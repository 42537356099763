@import url("https://use.typekit.net/pih7csi.css");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body { background: white; font-family: "Lato", sans-serif; overflow-y: hidden;}

header {
  & { height: 60px; left: 0; z-index: 100; right: 0; padding-left: 36px; padding-right: 36px; display: flex; align-items: center; position: fixed; background-color: #e74c3d; vertical-align: center; }
  .logo { padding-left: 8px; height: 36px; width: 119px; }
  .sidebar { cursor: pointer; }
  .profile {
    & { display: flex; align-items: center; justify-content: flex-end; width: 100%; font-family: "Lato", sans-serif; font-size: 24px; color: #ffffff; text-align: right; gap: 8px; }
    img { padding-right: 36px; }
  }
}

.pin { 
  & { position: "absolute"; transform: "translate(-50%, -50%)"; font-size: 20px; }
  .rectangle { 
    p { font-size: 16px; z-index: 10; border-radius: 10px; padding: 16px; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; width: 300px; height: 86px; background-color: white; position: relative; font-size: 16px; text-align: center; } 
    .location-icon { display: flex; justify-content: center; align-items: center; gap: 4px; font-size: 20px; } 
    .triangle { width: 0; height: 0; border-left: 15px solid transparent; border-right: 15px solid transparent; border-top: 15px solid #ffffff; position: absolute; bottom: -14px; left: 50%; transform: translateX(-50%); } 
  } 
  .bigger-circle { 
    & { opacity: 1; height: 104px; width: 104px; margin-left: -52px; margin-top: -52px; border-radius: 50%; background-color: rgba(231, 76, 61, 0.12); z-index: 1; display: flex; position: relative; align-items: center; justify-content: center; transition: 350ms ease-in-out; }
    .smaller-circle {
      & { position: absolute; display: flex; align-items: center; justify-content: center; height: 40px; width: 40px; border-radius: 50%; background-color: rgba(231, 76, 61, 0.3); z-index: 2; content: ""; }
      .circle { position: absolute; height: 16px; width: 16px; border-radius: 50%; background-color: #e74c3d; z-index: 5; content: ""; } 
    } 
  }
  .circle {
    & { border-radius: 50%; background-color: #e74c3d; width: 30px; height: 30px; position: absolute; opacity: 0; animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32); }
  }
  @keyframes scaleIn {
    from {
      transform: scale(0, 0);
      opacity: 1;
    }
    to {
      transform: scale(3, 3);
      opacity: 0;
    }
  }
}

.location {
  & { position: fixed; top: 60px; } 
  .map {
    & { height: calc(100vh - 128px); width: 100vw; }
    .map-item { height: 100%; width: 100%; }
  }
  .footer-container {
    & { position: relative; height: auto; display: flex; padding-top: 12px; padding-bottom: 12px; border-radius: 0px 0px 0px 0px; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; position: fixed; background-color: #e74c3d; bottom: 0; left: 0; right: 0; justify-content: center; transition: all 250ms ease-in-out; } 
    
    .show-footer { 
      & { position: absolute; left: 5px; top: -40px; display: none; width: min-content; background-color: #e74c3d; border: none; font-size: 20px; color:white; padding: 0px; margin: 0px; border-radius: 8px; }
      .MuiSvgIcon-root { color: #ffffff; font-size: 40px; }
    }
    .location-footer { display: flex; flex-direction: column; gap: 20px; font-size: 16px; font-family: "Lato", sans-serif; width: 90%;}
    //  .footer-title { padding-bottom: 10px; font-size: 24px;} 
    //  .show-footer{width: min-content; background-color: white; border: none; font-size: 20px; color:#e74c3d;}
    .footer-heading{ display: flex; } 
    .footer-title { padding-bottom: 0px; font-size: 24px; margin-right: 60%; }
    
    .details-row {
      & { display: flex; gap: 40px; margin-top: 0px; padding-top: 4px; }
      .single-detail {
        & { width: 100%; display: flex; align-items: center; justify-content: center; }
        &.panic-name { width: 180%; justify-content: space-between; color: #fff; }
        .service-title { display: none; }
        .phone-number { 
          & { color: #fff; font-size: 16px; } 
          a { 
            & { color: #fff !important; text-decoration: none; } 
            &:hover { text-decoration: underline; }
          }
        }
        .detail-title-row { display: flex; align-items: center; gap: 8px; }
        img { height: 32px; width: 32px; margin-right: 10px; }
      }
    }
  } 
  
  .footer-container-full { 
    & { height: auto; display: flex; padding-top: 18px; padding-bottom: 18px; border-radius: 0px 0px 0px 0px; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; position: fixed; background-color: #e74c3d; bottom: 0; left: 0; right: 0; justify-content: center; transition: 250ms ease-in-out; } 
    .location-footer { display: flex; flex-direction: column; gap: 20px; font-size: 20px; font-family: "Lato", sans-serif; width: 90%; position: relative; }
    .show-footer { 
      & { position: absolute; left: 5px; top: -40px; display: none; width: min-content; background-color: #e74c3d; border: none; font-size: 20px; color:white; padding: 0px; margin: 0px; border-radius: 8px; }
      .MuiSvgIcon-root { color: #ffffff; font-size: 40px; }
    }
    // .footer-title { padding-bottom: 10px; font-size: 24px; } 
    // .show-footer{width: min-content; background-color: white; border: none; font-size: 20px; color:#e74c3d; }
    .footer-heading {
      & { display: flex; } 
      .footer-title { padding-bottom: 0px; font-size: 24px; margin-right: 60%; }
      .show-footer { 
        & { width: min-content; background-color: #e74c3d; border: none; font-size: 20px; color:white; padding: 0; margin: 0; }
         .MuiSvgIcon-root { background-color: #e74c3d; color: #fff; }
      }
    }
    .details-row {
      & { display: flex; gap: 40px; margin-top: 0; padding-top: 0; }
      .single-detail {
        & { width: 100%; display: flex; align-items: center; justify-content: center; }
        &.panic-name { width: 180%; justify-content: space-between; color: #fff; }
        .service-title { display: none; }
        .phone-number { 
          & { color: #fff; font-size: 16px; } 
          a { 
            & { color: #fff !important; text-decoration: none; } 
            &:hover { text-decoration: underline; }
          }
        }
        .detail-title-row { display: flex; align-items: center; gap: 8px; }
        img { height: 32px; width: 32px; margin-right: 10px; }
      }
    }
  }
}


.warning-simulate{
  & { z-index: 1; top: 60px; padding: 0 20px; height: 112px; gap: 10px; width: 100%; position: fixed; display: flex; align-items: center; justify-content: center; background-color: #FCE79C; color: #000; }
  img { height: 64px; width: 64px; }
  span { font-size: 18px; font-weight: bold; }
}

.authorize{
  & { height: 100vh; display: flex; align-items: center; justify-content: center; margin: auto; }
  .card { 
    & { padding: 1rem; margin: 1rem; min-height: 360px; width: 460px; background-color: white; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 20px;  display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 15px; }
    p { text-align: center; margin: 2px;}
    img { height: 140px; width: 140px; }
    .buttons { display: flex;align-items: center;  gap: 20px; width: 100%; }
  }
}

.confirm-authorize-modal {
  & { display: flex; flex-direction: column;align-items: center; padding:  0 1rem; }
  .close-div { display: flex; justify-content: end; width: 100%; }
  .title { font-weight: bold; font-size: 34px; }
  p { text-align: center; }
}


@media only screen and (max-width: 800px) {

  .location { top: 0; }
  .location .footer-container-full { height: 40px !important; }
  .location .footer-container .show-footer { display: block; }  
  .location .footer-container-full .show-footer { display: block; }  
  .location .footer-container-full .details-row .single-detail.panic-name { width: 100%; }
  .location .footer-container .details-row .single-detail.panic-name { width: 100%; }
  .location .footer-container-full .details-row .single-detail .service-title { display: block; }
  .location .footer-container .details-row .single-detail .service-title { display: block; }
  .location .footer-container .show-footer { display: block; top: -33px; }
  
  header { height: 0; justify-content: center; display: flex; }
  header .logo { display: none !important; }

  .warning-simulate{
    & { top: 0; width: calc(100% - 40px); z-index: 100; }
    span { font-size: 15px; }
    img { width: 50px; height: 50px; }
  }
        
  .location .map { height: calc(100vh - 76px); }
      
  .location { 
    
      
    // .footer-container { 
    //   & { height: auto; display: flex; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; position: fixed; background-color: #e74c3d; bottom: 0; left: 0; right: 0; justify-content: center; }
    //   .location-footer { 
    //     & { display: flex; flex-direction: column; gap: 0px; font-size: 16px; font-family: "Lato", sans-serif; }
    //     .footer-heading { display: flex; justify-content: space-between; } 
    //     .footer-title { padding-bottom: 10px; font-size: 18px; margin-right: 10%; visibility: hidden;}
    //     .show-footer {
    //       & { width: min-content; border: none; font-size: 18px; color:#e74c3d !important;}
    //       .details-row { 
    //         & { display: flex; gap: 20px !important; font-size: 16px; }
    //         .single-detail {
    //           & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
    //           .phone-number { color: #fff; font-size: 16px; }
    //           .detail-title-row { 
    //             & { display: flex; align-items: center; gap: 8px; }
    //             img { height: 32px; width: 32px; }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
        
    
    .location-footer {
      & { display: flex; flex-direction: column; gap: 0px; font-size: 18px; font-family: "Lato", sans-serif; }
      .footer-heading { display: flex; justify-content: space-between; } 
      .footer-title { padding-bottom: 0px; font-size: 18px; margin-right: 10%; visibility: hidden;}
      // .show-footer { width: min-content; border: none; font-size: 18px; color:#e74c3d !important; }
      .details-row {
        & { display: flex; flex-direction: column; gap: 30px !important; font-size: 16px; }
        .single-detail {
          & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
          .phone-number { color: #fff; font-size: 16px; } 
          .detail-title-row {
            & { display: flex; align-items: center; gap: 8px; color: #fff; }
            img { height: 32px; width: 32px; }
          }
        }
      }
    }

  }
        
  .location-footer { width: 100%; font-size: 14px; padding: 10px !important; gap: 10; }
  .footer-title { font-size: 20px; }
  .details-row { display: flex; flex-direction: column; gap: 10px; }

}

@media only screen and (min-width: 600px) {
  .footer-container { height: 44px !important; }
}

//GOOGLE MAP STYLES
.gmnoprint[role=menubar] {
  & { bottom: 30px !important; max-height: 40px; }
}